@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/colors";

.formControl {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.tool {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
}

.toolControls {
  margin: 0 6px;
}
.tools {
  margin-bottom: 45px;
  margin-top: 45px;
}

.button {
  min-width: 100px;
  margin-left: 15px;
}

.category {
  min-width: 200px;
}

.addTools {
  margin-bottom: 45px;
  margin-top: 30px;
}

.toolGroupTitle {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 20px;
}

.toolGroup {
  margin-bottom: 30px;
}

.form {
  position: relative;
}

.fetchingForm {
  position: absolute;
  left: 0;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  right: 0;
  display: flex;
  top: 0;
  bottom: 0;
  z-index: 1;
}
