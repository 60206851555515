body {
  background-color: $bg-gray;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Force ant input number to take up all width when used inside col */
.ant-col {
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
}

// Fix for blurry images on retina https://stackoverflow.com/questions/13347947/css-filter-on-retina-display-fuzzy-images
img {
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
}

// Fix for long image names on Upload component
.ant-upload-list-text .ant-upload-list-item-name,
.ant-upload-list-picture .ant-upload-list-item-name {
  max-width: 300px;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  object-fit: cover;
}

// Fix the margin between the help text and the next form field
.ant-form-item-with-help {
  margin-bottom: $spacing-4;
}

// Fix the styles sidebar
.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  padding: 16px 0;
}

.ant-menu-item {
  padding-left: 17px !important;
}

.ant-form-item-margin-offset {
  margin-bottom: -15px !important;
}
