@import "../../assets/styles/variables";
@import "../../assets/styles/colors";

.title {
  font-size: 24px;
}

.layout {
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: transparent;
  padding: 30px;
}

.img {
  width: 80%;
  margin-bottom: 16px;
  max-width: 300px;

  &.customImg {
    max-width: 600px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  }

  img {
    width: 100%;
    vertical-align: middle;
  }

  & svg {
    width: 100%;
    height: 300px;
    fill: $primary-color;
  }
}
