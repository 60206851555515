.emptyStateWrapper {
  text-align: center;
  margin: 45px auto;
  max-width: 400px;
}

.emptyTitle {
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 5px;
  margin-top: 20px;
}
