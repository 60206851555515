@import "../../../../assets/styles/colors";
@import "../../../../assets/styles/variables";

.container {
  height: 100%;
}

.content {
  background: $bg-white;
  padding: $spacing-6;
  margin: 0 $spacing-6;
}

.rowFullScreen {
  min-height: 100%;
}

.contentCentered {
  align-content: center;
}

.pageHeader {
  :global {
    .ant-page-header-footer .ant-tabs .ant-tabs-tab {
      font-size: $font-size-14;
    }
  }
}

.pageHeader,
.filters {
  margin-bottom: $spacing-6;
}

.innerHeader {
  font-weight: 500;
  margin-bottom: $spacing-7;
}

.appsContainer {
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
}
