.panel {
  margin-bottom: 20px;
  background: none;
  border: solid 1px #61affe;
  border-radius: 8px !important;
  font-family: "Source Code Pro", monospace;
  font-size: 14px;

  .ant-collapse-content-box,
  .ant-collapse-content {
    border-radius: 0 0 8px 8px;
  }

  .ant-empty-image {
    height: 50px;
  }
  .ant-empty {
    margin: 10px auto;
  }
}

.tag-title {
  margin-bottom: 15px;
  margin-top: 30px;
  font-weight: bold;
  font-size: 20px;
}

.panelGroup {
  background: none;
  border: none;
}

.get {
  border-color: #61affe !important;
  background: #e0efff;
  color: #3b4151;
}

.post {
  border-color: #49cc90 !important;
  background: #dff5e9;
  color: #3b4151;
}

.put {
  border-color: #fca130 !important;
  background: #feecd9;
  color: #3b4151;
}

.putch {
  border-color: #50e3c2 !important;
  background: #e0f9f3;
  color: #3b4151;
}

.delete {
  border-color: #f93e3e !important;
  background: #ffdbd9;
  color: #3b4151;
}
