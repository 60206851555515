@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/colors";

.list {
  background: #fff;
  padding: 15px 24px;
  min-height: 100%;
}

.item {
  color: #3b4151;
  margin-bottom: 10px;
  border-radius: 4px;
  border: solid 1px #3b4151;
  padding: 8px 12px;
  position: relative;
}

.get {
  border-color: #61affe;
  background: rgba(97, 175, 254, 0.2);
}

.post {
  border-color: #49cc90;
  background: rgba(73, 204, 144, 0.2);
}

.put {
  border-color: #fca130;
  background: rgba(252, 161, 48, 0.2);
}

.patch {
  border-color: #50e3c2;
  background: rgba(80, 227, 194, 0.2);
}

.delete {
  border-color: #f93e3e;
  background: rgba(249, 62, 62, 0.2);
}

.buttonAdd {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.5;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  &:hover {
    opacity: 1;
  }
}
