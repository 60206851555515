$primary-color: #5e5dff;
$primary-color-purple: #4b4ace;
$text-apigale-white: #d9e3ea;
$text-white: #fff;

$bg-apigale-black: #151719;
$bg-apigale-dark: #25282c;
$bg-white: #fff;
$bg-gray: #f5f5f5;
$warning: #fc5151;

$layout-sider-background: $bg-apigale-dark;


$color-100: #E0E7FF;
$color-200: #B0C7FF;
$color-300: #80A7FF;
$color-400: #4F87FF;
$color-500: #2A6FFF;
$color-600: #005FDE; /* Our Baseline Colour*/
$color-700: #004BB5;
$color-800: #003A8C;
$color-900: #002A6A;


$color-gray: #e6e7e9;
$color-green: #22c55e;
$color-green-light:rgba(34, 197, 94, 0.12);
$color-red-light:rgba(153, 27, 27, 0.12);
$color-red:#991b1b;
$color-orange: #f39627;
