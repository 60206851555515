@import "../../../../assets/styles/colors";
@import "../../../../assets/styles/variables";

.ant-btn-primary {
  &:focus,
  &:active {
    color: $text-white;
    background-color: $primary-color-purple !important;
    border-color: $primary-color-purple !important;
  }

  &.ant-btn-dangerous {
    &:focus,
    &:active {
      border-color: #fc5151 !important;
      background: #fc5151 !important;
    }
  }
}

.noPadding {
  padding-left: 0;
  padding-right: 0;
}
