@import "../../../../assets/styles/colors";
@import "../../../../assets/styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
}

.formWrapper {
  width: 290px;
}

.form {
  text-align: left;
}

.icon {
  color: $primary-color;
}

.button {
  width: 100%;
  margin-top: $spacing-4;
}

h1.title {
  font-size: 27px;
  margin-bottom: $spacing-10;
}

.logo {
  width: 50px;
  margin-bottom: $spacing-4;
}
