.toolInfo {
  max-width: 80%;
}

.toolDescription {
  margin-top: 5px;
  font-size: 12px;
}

.toolGroupTitle {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 20px;
}

.toolGroup {
  margin-bottom: 30px;
}

.tool {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
}
