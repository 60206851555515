@import "../../assets/styles/variables";
@import "../../assets/styles/colors";

.tooltip {
  position: absolute;
  background: #3b4151;
  color: #ffffff;
  z-index: 100;
  top: 0;
  left: 0;
  border-radius: 8px;
  padding: 10px 15px;
  display: inline-block;
  line-height: 1em;
  font-size: 12px;
}
