@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/colors";

.form {
  text-align: left;

  .ant-form-item {
    margin-bottom: 45px;
  }
  .ant-form-item-explain-error {
    font-size: 12px;
  }
}

.apiLabel {
  font-size: 14px;
  margin-bottom: $spacing-2;
}

.button {
  width: 100%;
  margin-top: $spacing-4;
  margin-right: 20px;
}

.wrapButtons {
  display: flex;
  max-width: 280px;
}
