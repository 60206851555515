.button {
  margin-left: 15px;
  min-width: 70px;
}

.subTitle {
  display: inline-block;
}

.subTitleGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 45px;
}
