@import "../../../../assets/styles/variables";

.modal {
  overflow: hidden;
  :global {
    .ant-modal-body {
      padding: $spacing-6 $spacing-6 $spacing-4;
    }
  }
}

.divider {
  position: relative;
  width: calc(100% + 100px);
  left: -50px;
  margin-bottom: $spacing-4;
}

.submitButton {
  margin-left: $spacing-3;
}
