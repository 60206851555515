.apps-sidebar {
  padding: 0 24px 24px 0;
  min-width: 100%;
  width: 100%;
}

.apps-sidebar-content {
  background: #fff;
  padding: 0;
  width: 100%;
  margin: 0 24px 0 0;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);
}

.ant-menu-sub.ant-menu-inline {
  .ant-menu-item {
    padding-left: 36px !important;
  }
}

.ant-tree {
  .ant-tree-treenode {
    padding: 8px 5px;
  }

  .ant-tree-title {
    flex: 1;
    & > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      button {
        border: none;
        background: none;
        cursor: pointer;
      }
    }
  }
}
