.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: transparent;

  &.isFullscreen {
    height: -webkit-fill-available;
    height: -moz-available;
    height: 100vh;
    height: stretch;
  }
}
